export const isValidEmail = (email) => {
  const regExp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
}

export const scrollToElement = (elem = "") => {
  const element = document.getElementById(elem)
  return element?.scrollIntoView({ block: "center", behavior: 'smooth' });
}

export const scrollTo = (el) => {
  setTimeout(() => {
    scrollToElement(el);
  }, 1000);
};

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

export const numberFormatter = (number) => Number(number).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$& ');

export const decimalsRound = (number) => {
  return Math.round(number * 100) / 100
}
export const onlyNumbersRegex = (value) => {
  const regex = /^[0-9]+$/
  return regex.test(value)
}

const deltaRating = []
const step = 0.4
const starFrom = 2.1
let lastNumber = 0, min = 0, max = 0

for (let i = 3; i <= 100; i++) {
  const sugg = []
  const del = i / 10

  if (i === 3) {
    sugg.push(starFrom, starFrom + step)
    lastNumber = starFrom + step
  } else {
    min = lastNumber + 0.1
    max = min + step
    sugg.push(min, max)
    lastNumber = max
  }

  deltaRating.push({
    delta: del,
    sugg
  })
}

export const getDelta = (winnerRating, loserRating) => {
  let res = { forWin: 0, forLose: 0 }

  const diff = (winnerRating - loserRating).toFixed(1)
  const absDiff = Math.abs(diff)
  const newDiff = Number(diff)


  if (absDiff >= 0 && absDiff <= 2) {
    res = { forWin: 0.2, forLose: -0.2 }
  } else if (newDiff >= starFrom && newDiff <= 13) {
    res = { forWin: 0.1, forLose: -0.1 }
  } else if (newDiff > 13) {
    res = { forWin: 0, forLose: 0 }
  } else if (newDiff < -2) {

    let d = 0
    
    deltaRating.forEach(({ sugg, delta }) => {
      const fIndex = sugg[0] <= absDiff && sugg[1] >= absDiff
      if (fIndex) {
        d = delta
      }
    })

    res = { forWin: d, forLose: d * -1 }
  }
  return res
}