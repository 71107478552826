import React from "react";
import { Link } from 'react-router-dom';

import "./styles.scss"


function Footer() {

    return (
        <div className="footer">
            <div className="footer-container container">
                <div className="container">
                    <div className="col-md-4">
                        <div className="widget">
                            <h2 className="widget-title">Администрация</h2>
                            <ul className="widget-contact">
                                <li className="widget-contact-phone">111-111-111</li>
                                <li className="widget-contact-phone">222-222-222</li>
                                {/* <li className="widget-contact-mail"><a href="mailto:mail@mail.com">mail@mail.com</a></li> */}
                                <li className="widget-contact-address">Moldova, or. Chisinau<br />str. Bucuriei 12A<br />Top Cup Academy</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="widget">
                            <h2 className="widget-title">Последнее на сайте</h2>
                            <ul className="widget-latest-posts">
                                <li>
                                    <span className="widget-latest-posts-cover">
                                        <a href="#">
                                            <img src="images/photos/travel/post-1.jpg" alt="post image" />
                                        </a>
                                    </span>
                                    <h4><a href="#">Заголовок публикуемой на сайте новости</a></h4>
                                    <span className="widget-latest-posts-time">Дата : 31 авг</span>
                                </li>
                                <li>
                                    <span className="widget-latest-posts-cover">
                                        <a href="#">
                                            <img src="images/photos/technology/post-2.jpg" alt="post image" />
                                        </a>
                                    </span>
                                    <h4><a href="#">Заголовок публикуемой на сайте новости</a></h4>
                                    <span className="widget-latest-posts-time">Дата : 31 августа</span>
                                </li>
                                <li>
                                    <span className="widget-latest-posts-cover">
                                        <a href="#">
                                            <img src="images/photos/sport/post-3.jpg" alt="post image" />
                                        </a>
                                    </span>
                                    <h4><a href="#">Заголовок публикуемой на сайте новости</a></h4>
                                    <span className="widget-latest-posts-time">Дата : 31 августа</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="widget">
                            <h2 className="widget-title">Мы в соц. сетях</h2>
                            <ul className="widget-follow-us">
                                <li><a className="widget-follow-us-facebook" href="http://html6.com.ru/">Facebook</a></li>
                                <li><a className="widget-follow-us-twitter" href="http://html6.com.ru/">Twitter</a></li>
                                <li><a className="widget-follow-us-dribbble" href="http://html6.com.ru/">Dribbble</a></li>
                                <li><a className="widget-follow-us-google" href="http://html6.com.ru/">Google+</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright container">
                    <div className="footer-contantcs">
                        <div>
                            <div>
                                2024 | TCA Rating.
                            </div>
                            <div>
                                Статистика собирается с <b>17.03.2024</b>
                            </div>
                            <div>
                                Стать партнёром на <b><a href="https://www.patreon.com/c/TCARating" target="_blank">Patreon</a></b>
                            </div>
                            <div>
                                MIA <b>+37369496713</b>
                            </div>
                        </div>
                        <div>
                            <div className="widget-contact-address">Moldova, or. Chisinau<br />str. Bucuriei 12A - Top Cup Academy</div>
                        </div>
                    </div>


                    <>
                        <div className="footer-links">
                            <Link to="/privacy-policy">
                                Privacy policy
                            </Link>
                        </div>
                    </>
                </div>


            </div>
        </div>
    );
}

export default Footer