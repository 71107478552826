import React, { useState, useEffect } from "react";
import AppMainLayout from "../../../layouts/app-main-layout"
import classNames from "classnames"
import { GET_PLAYER_INFO, NO_IMAGE_URL, EDIT_USER_PROFILE } from "../../../helpers/constants"
import { amountFormatter } from "../../../helpers/constants/functions"
import axiosFetch from "../../../helpers/axios"
import { useSelector } from "react-redux";
import {
    getAttachedUserId
} from "../../../selectors/mainSelector";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from "notistack";
import Input from "../../../helpers/input";
import { Redirect, Link } from "react-router-dom";
import CookieHelper from "../../../helpers/cookie-helper";
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import "./styles.scss"

function Profile() {

    const userMockData = {
        amplua: "",
        backhand: "",
        blade: "",
        coach: "",
        forhand: "",
        hand: "",
        hvat: "",
        image: "",
        name: "",
        surname: "",
        phone: "",
    }


    const attachedUserId = useSelector(getAttachedUserId);

    const [isFetching, setIsFetching] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [playerInfo, setPlayerInfo] = useState({ ...userMockData })
    const [oldPlayerInfo, setOldPlayerInfo] = useState({ ...userMockData })
    const [photoPreview, setPhotoPreview] = useState()
    useEffect(() => {
        const oAuth = CookieHelper("get", "oauth");
        !oAuth && setRedirect(true)
    }, [attachedUserId])

    useEffect(() => {
        if (attachedUserId.email) {
            setIsFetching(true)
            const formData = new FormData()
            formData.append("playerId", attachedUserId?.playerInfo?.id);

            axiosFetch(GET_PLAYER_INFO, formData).then(res => {
                console.log("res", res)
                setPlayerInfo(res[0])
                setOldPlayerInfo(res[0])
                setIsFetching(false)
            })
        }
    }, [attachedUserId])

    const onChange = (state, value) => {
        const data = {
            ...playerInfo,
            [state]: value,
        };

        setPlayerInfo(data);
    }

    const handleChangePhoto = (file) => {
        const data = {
            ...playerInfo,
            image: file
        }
        setPlayerInfo(data)
        setPhotoPreview(URL.createObjectURL(file))
    }

    const saveProfile = () => {
        const formData = new FormData()
        formData.append("name", playerInfo.name);
        formData.append("surname", playerInfo.surname);
        formData.append("amplua", playerInfo.amplua);
        formData.append("hvat", playerInfo.hvat);
        formData.append("hand", playerInfo.hand);
        formData.append("blade", playerInfo.blade);
        formData.append("forehand", playerInfo.forhand);
        formData.append("backhand", playerInfo.backhand);
        formData.append("coach", playerInfo.coach);
        formData.append("image", playerInfo.image);
        formData.append("phone", playerInfo.phone);
        formData.append("id", attachedUserId?.playerInfo?.id);
        axiosFetch(EDIT_USER_PROFILE, formData).then(res => {

            if (res === "ok") {
                enqueueSnackbar(("Сохранено"), { variant: "success" });
                setOldPlayerInfo(playerInfo)
            } else {
                enqueueSnackbar(("Что-то пошло не так"), { variant: "error" });
            }
            setIsSaving(false)
        })
    }

    const finishImage = playerInfo.image.length === 0 ? NO_IMAGE_URL : playerInfo.image
    const imageSrc = photoPreview ? photoPreview : finishImage;

    const disabledButton = JSON.stringify(playerInfo) === JSON.stringify(oldPlayerInfo)

    const renderPlayerCard = () => {
        return (
            <div className="card player-card player-profile">
                {redirect && <Redirect to={`/`} />}
                {/* <div className="card-bg" /> */}
                <div className="card-body">
                    <Link to={`/player/${attachedUserId?.playerInfo?.id}`} className="show-stat">Посмотреть статистику <StackedLineChartIcon /></Link>
                    <div className="d-flex flex-column align-items-center text-center player-data">
                        {/* <img src={test} alt="Admin" className="rounded-circle w-100" width="150" /> */}
                        <div
                            className={classNames({
                                'player-photo': true,
                                'b-cotainer': !playerInfo.image,
                                'profile-photo': true
                                //`${cName}`: true
                            })}

                            style={{ backgroundImage: `url(${imageSrc})` }}></div>

                        <div className="place-rating profile">
                            {Number(playerInfo.visibleInRating) > 0 ?
                                <p className="text-muted font-size-m player-place-custom">#{playerInfo.place} </p> :
                                <p className="text-muted font-size-m player-place-custom no-activity">Нет активности</p>
                            }
                            {Number(playerInfo.visibleInRating) > 0 && <p className="text-muted font-size-m abs-rating">R{amountFormatter(playerInfo.rating)}</p>}
                        </div>

                        <Input
                            colMd={""}
                            type={"file"}
                            placeholder={"Фотография игрока"}
                            name={"avatar"}
                            onChange={(e) => handleChangePhoto(e.target.files[0])}
                        />
                        <div className="mt-3 player-info">

                            <TextField
                                id="outlined-basic-1"
                                label="Имя"
                                fullWidth
                                variant="outlined"
                                value={playerInfo.name}
                                onChange={(e) => onChange("name", e.target.value)}
                            />
                            <br /><br />
                            <TextField
                                id="outlined-basic-1"
                                label="Фамилия"
                                fullWidth
                                variant="outlined"
                                value={playerInfo.surname}
                                onChange={(e) => onChange("surname", e.target.value)}
                            />
                            <br /><br />
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Амплуа</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={playerInfo.amplua}
                                    onChange={(e) => onChange("amplua", e.target.value)}
                                >
                                    <FormControlLabel value="Атакующий игрок" control={<Radio />} label="Атакующий игрок" />
                                    <FormControlLabel value="Защитник" control={<Radio />} label="Защитник" />
                                    <FormControlLabel value="Атакующий Защитник" control={<Radio />} label="Атакующий Защитник" />
                                    <FormControlLabel value="Блокер" control={<Radio />} label="Блокер" />
                                    <FormControlLabel value="Универсальный" control={<Radio />} label="Универсальный (Allround)" />
                                </RadioGroup>
                            </FormControl>
                            <br /><br />
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Хват</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={playerInfo.hvat}
                                    onChange={(e) => onChange("hvat", e.target.value)}
                                >
                                    <FormControlLabel value="euro" control={<Radio />} label="Европейская" />
                                    <FormControlLabel value="asia" control={<Radio />} label="Азиатская/Перо" />
                                </RadioGroup>
                            </FormControl>
                            <br /><br />

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Рабочая рука</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={playerInfo.hand}
                                    onChange={(e) => onChange("hand", e.target.value)}
                                >
                                    <FormControlLabel value="left" control={<Radio />} label="Левая рука" />
                                    <FormControlLabel value="right" control={<Radio />} label="Правая рука" />
                                </RadioGroup>
                            </FormControl>
                            <br /><br />

                            <TextField
                                id="outlined-basic-1"
                                label="Основание"
                                fullWidth
                                variant="outlined"
                                value={playerInfo.blade}
                                onChange={(e) => onChange("blade", e.target.value)}
                            />
                            <br /><br />
                            <TextField
                                id="outlined-basic-1"
                                label="Forehand"
                                fullWidth
                                variant="outlined"
                                value={playerInfo.forhand}
                                onChange={(e) => onChange("forhand", e.target.value)}
                            />
                            <br /><br />
                            <TextField
                                id="outlined-basic-1"
                                label="Backhand"
                                fullWidth
                                variant="outlined"
                                value={playerInfo.backhand}
                                onChange={(e) => onChange("backhand", e.target.value)}
                            />
                            <br /><br />
                            <TextField
                                id="outlined-basic-1"
                                label="Тренер"
                                fullWidth
                                variant="outlined"
                                value={playerInfo.coach}
                                onChange={(e) => onChange("coach", e.target.value)}
                            />
<br /><br />
                            <TextField
                                id="outlined-basic-1"
                                label="Телефон"
                                fullWidth
                                variant="outlined"
                                value={playerInfo.phone}
                                onChange={(e) => onChange("phone", e.target.value)}
                            />

                            <Button
                                onClick={() => saveProfile()}
                                variant="contained"
                                className="save-profile"
                                disabled={disabledButton || isSaving}
                            >
                                Сохранить
                            </Button>



                        </div>
                    </div>
                </div>
            </div>
        )

    }


    return (
        <AppMainLayout>
            <div className="row profile-page">
                <div
                    className={classNames({
                        'col-md-12': true,
                        'is-fetching': isFetching,
                    })}>
                    {isFetching ? <CircularProgress className="circular-progress" size={70} /> : renderPlayerCard()}
                </div>
            </div>
        </AppMainLayout>
    );
}


export default Profile