
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/';
import Store from "./store/Store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import moment from "moment";
import { GoogleOAuthProvider } from '@react-oauth/google';

const history = createBrowserHistory();

moment.locale(Store.getState().PrefsReducer?.lng);

ReactDOM.render(
    <Provider store={Store}>

        <Router history={history} basename='/'>
            <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}>
                <GoogleOAuthProvider clientId="272304584946-b33ip0l25srmtqbs488ointfp0ijqte0.apps.googleusercontent.com">
                    <App />
                </GoogleOAuthProvider>
            </SnackbarProvider>
        </Router>

    </Provider>,
    document.getElementById('root')
);

