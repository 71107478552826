import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CookieHelper from "../../helpers/cookie-helper";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import topcup from "../../images/topcup.jpg"
import { GoogleLogin } from '@react-oauth/google';
import classNames from "classnames"
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import {
    getAttachedUserId
} from "../../selectors/mainSelector";
import CircularProgress from '@mui/material/CircularProgress';
import axiosFetch from "../../helpers/axios"
import {
    GET_ATTACHED_PLAYER_ID,
    ADMIN_EMAIL,
    GET_PLAYERS_LIST,
    REQUEST_ATTACH_PLAYER_ID,
    GET_NOTIFICATION_LIST,
    REQUEST_ACTION
} from "../../helpers/constants"
import { setAttachedPlayerId } from "../../actions/LoginActionCreators";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { enqueueSnackbar } from "notistack";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import "./styles.scss"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Header({ scrollPosition }) {
    const dispatch = useDispatch();
    const attachedUserId = useSelector(getAttachedUserId);

    // const [showModal, setShowModal] = useState(false);
    // const [tcaToken, setTcaToken] = useState(null);
    // const [password, setPassword] = useState("");
    // const [login, setLogin] = useState("");
    // const [error, setError] = useState("");
    const defaultAction = { action: "", id: "", state: false, playerId: null }
    const [oAuthLogin, setOAuthLogin] = useState();
    const [showExitModal, setShowExitModal] = useState(false);
    const [showNotifModal, setShowNotifModal] = useState(false);
    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
    const [fetchAttachedPlayer, setFetchAttachedPlayer] = useState(false);
    const [isFetchingPlayers, setIsFetchingPlayers] = useState(false);
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [isActionRequest, setIsActionRequest] = useState(false);
    const [requestAction, setRequestAction] = useState({ ...defaultAction });

    const [playersList, setPlayersList] = useState([]);
    const [notifList, setNotifList] = useState([]);
    const [showAtachPlayerPlayerModal, setShowAtachPlayerPlayerModal] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const IS_ADMIN = oAuthLogin?.email === ADMIN_EMAIL;

    useEffect(() => {
        const oAuth = CookieHelper("get", "oauth");
        oAuth?.email && setOAuthLogin(oAuth);
    }, [])

    useEffect(() => {
        if (IS_ADMIN) {
            console.log("IS_ADMIN111", IS_ADMIN)
            axiosFetch(GET_NOTIFICATION_LIST).then(res => {
                console.log("IS_ADMIN222", IS_ADMIN)
                setNotifList(res)
                // setIsFetchingPlayers(false)
            })
        }
    }, [oAuthLogin])

    useEffect(() => {

        if (oAuthLogin?.email && !attachedUserId.playerInfo?.id) {
            setFetchAttachedPlayer(true)
            const formData = new FormData()
            formData.append("email", oAuthLogin?.email)

            axiosFetch(GET_ATTACHED_PLAYER_ID, formData).then((res) => {
                setFetchAttachedPlayer(false)
                dispatch(setAttachedPlayerId(res[0]))
            })
        }
    }, [oAuthLogin])



    useEffect(() => {

        if (playersList.length === 0 && showAtachPlayerPlayerModal) {
            setIsFetchingPlayers(true)

            const formData = new FormData()
            formData.append("noAttached", 1)
            axiosFetch(GET_PLAYERS_LIST, formData).then(res => {
                setPlayersList(res)
                setIsFetchingPlayers(false)
            })
        }


    }, [showAtachPlayerPlayerModal])

    const onAttachModal = () => {
        setIsSendingRequest(true)

        const formData = new FormData()
        formData.append("email", oAuthLogin?.email)
        formData.append("request_attach_player_id", selectedPlayer?.id);

        axiosFetch(REQUEST_ATTACH_PLAYER_ID, formData).then((res) => {

            if (res === "Запрос успешно отправлен") {
                enqueueSnackbar((res), { variant: "success" });
            } else {
                enqueueSnackbar((res), { variant: "warning" });
            }


            setTimeout(() => {
                setShowAtachPlayerPlayerModal(false)
                setFetchAttachedPlayer(false)
                setIsSendingRequest(false)
                window.location.reload();
            }, 1000);

        })
    }

    // const setToken = () => {
    //     var token = "";
    //     var possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

    //     for (var i = 0; i < 50; i++)
    //         token += possible.charAt(Math.floor(Math.random() * possible.length));

    //     return token;
    // }

    // const onAdminMode = (action = "login") => {
    //     if (action === "login" && Number(password) !== 4142135) {
    //         setError("Пароль неверный...")
    //     } else if (action === "logout") {
    //         CookieHelper("remove", "tca-token");
    //         window.location.reload();
    //     } else {
    //         CookieHelper("set", "tca-token", setToken());
    //         window.location.reload();
    //     }
    // }

    const renderNotifList = () => {
        const content = notifList.map((notif, index) => {
            const { id, email, requestDate, playerInfo } = notif
            return (
                <div className="notif-line">
                    <div>
                        <div> {email}</div>
                        <div>{playerInfo[0].name} {playerInfo[0].surname}r{playerInfo[0].rating}</div>
                        <div>{requestDate}</div>
                    </div>


                    <div className="request-actions-icons">
                        <CheckCircleIcon onClick={() => setRequestAction({ action: "approve", id, state: true, playerId: playerInfo[0].id })} />
                        <DeleteForeverIcon onClick={() => setRequestAction({ action: "decline", id, state: true, playerId: playerInfo[0].id })} />
                    </div>
                </div>
            )
        })

        return content
    }

    const renderNotifModal = () => {

        return (
            <Dialog
                open={showNotifModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowNotifModal(false)}
                aria-describedby="alert-dialog-slide-description"
                className="request-modal"
            >

                <DialogTitle >Список запросов</DialogTitle>
                <DialogContent >

                    <Grid container >
                        <Grid item xs={12} className="auth-field">
                            {notifList.length === 0 ? "Нет запросов" : renderNotifList()}
                        </Grid>


                    </Grid>

                </DialogContent>
                <DialogActions >
                    <Button
                        onClick={() => {
                            setShowNotifModal(false);
                        }}
                        variant="contained"
                    >
                        Отмена
                    </Button>
                </DialogActions>

            </Dialog >
        )
    }

    const renderAttachPlayerModal = () => {

        return (
            <Dialog
                open={showAtachPlayerPlayerModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowAtachPlayerPlayerModal(false)}
                aria-describedby="alert-dialog-slide-description"
                className="auth-exit-modal"
            >

                <DialogTitle >Привязать игрока к профайлу</DialogTitle>
                <br />
                <DialogContent >

                    <Grid container >
                        <Grid item xs={12} className="auth-field">
                            {isFetchingPlayers ? <CircularProgress size={12} /> :
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo-2"
                                    options={playersList}
                                    autoHighlight
                                    value={selectedPlayer}
                                    onChange={(event, newValue) => {
                                        setSelectedPlayer(newValue);
                                    }}
                                    getOptionLabel={(option) => `${option.name} (${option.rating})`}
                                    renderInput={(params) => <TextField {...params} label="Выберите игрока" />}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.name} ({option.rating})
                                        </Box>
                                    )}
                                />
                            }

                        </Grid>


                    </Grid>

                </DialogContent>
                <DialogActions >
                    <Button
                        onClick={() => {
                            setShowAtachPlayerPlayerModal(false);
                            setSelectedPlayer(null);
                            setIsFetchingPlayers(false)
                        }}
                        variant="contained"
                    >
                        Отмена
                    </Button>
                    <Button
                        onClick={() => onAttachModal()}
                        variant="contained"
                        disabled={!selectedPlayer?.id || isFetchingPlayers}
                    >
                        Отправить запрос
                        {isSendingRequest && <CircularProgress size={18} />}
                    </Button>
                </DialogActions>

            </Dialog >
        )
    }

    const onApproveDecline = () => {
        setIsActionRequest(true)

        const formData = new FormData()
        formData.append("action", requestAction.action)
        formData.append("id", requestAction.id)
        formData.append("requestPlayerId", requestAction.playerId)

        axiosFetch(REQUEST_ACTION, formData).then((res) => {

            if (res === "OK") {
                enqueueSnackbar(("Игрок успешно привязан"), { variant: "success" });
            } else {
                enqueueSnackbar(("Запрос отклонен"), { variant: "warning" });
            }

            setIsActionRequest(false)
            setShowNotifModal(false)
            setRequestAction({ ...defaultAction })
            window.location.reload();
        })
    }

    const renderApproveModal = () => {
        const action = requestAction.action === "approve" ? "Подтверждаем привязку аккаунта?" : "Отклоняем привязку аккаунта?"
        return (
            <Dialog
                open={requestAction.state}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowExitModal(false)}
                aria-describedby="alert-dialog-slide-description"
                className="auth-exit-modal"
            >
                <div>
                    <DialogTitle >{action}</DialogTitle>
                    <DialogActions >
                        <Button
                            onClick={() => setRequestAction({ ...defaultAction })}
                            variant="contained"
                        >
                            Отмена
                        </Button>
                        <Button
                            disabled={isActionRequest}
                            onClick={() => onApproveDecline()}
                            variant="contained"
                        >
                            ОК
                            {isActionRequest && <CircularProgress size={18} />}
                        </Button>


                    </DialogActions>
                </div>

            </Dialog>
        )
    }
    const renderExitModal = () => {

        return (
            <Dialog
                open={showExitModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowExitModal(false)}
                aria-describedby="alert-dialog-slide-description"
                className="auth-exit-modal"
            >
                <div>
                    <DialogTitle >Выходим?</DialogTitle>
                    <DialogActions >
                        <Button
                            onClick={() => setShowExitModal(false)}
                            variant="contained"
                        >
                            Отмена
                        </Button>
                        <Button
                            // onClick={() => onAdminMode("logout")}
                            onClick={() => onOAuth()}
                            variant="contained"
                        >
                            Выйти
                        </Button>


                    </DialogActions>
                </div>

            </Dialog>
        )
    }



    const checkForSelectedMenu = (prefix, isHomePage = false) => {
        if (isHomePage) {
            const currentUrl = window.location.href

            const noInCheckDelta = currentUrl.includes("/check-delta")
            const noInAllEvents = currentUrl.includes("/events")
            const noInAllPlayers = currentUrl.includes("/players") || currentUrl.includes("/player")
            const noInAddResult = currentUrl.includes("/add-result")
            const noInAdsResult = currentUrl.includes("/ads")

            return !noInCheckDelta && !noInAllEvents && !noInAllPlayers && !noInAddResult && !noInAdsResult
        } else {
            const currentUrl = window.location.href;
            return currentUrl.includes(prefix)
        }

    }

    const onOAuth = () => {
        CookieHelper("remove", "oauth");
        window.location.reload();
    }

    // const oAuth = useGoogleLogin({
    //     onSuccess: codeResponse => {

    //         // const credentials = codeResponse;
    //         console.log("++++++++++++++++++++++++", codeResponse)
    //         CookieHelper("set", "oauth", JSON.stringify(codeResponse));
    //         window.location.reload();
    //         // setMyCredentials(codeResponse)

    //     },
    //     flow: 'auth-code',
    // });

    const renderAdminNotif = () => {
        return (
            <div className="notif-icon" onClick={() => setShowNotifModal(true)}>
                ({notifList.length})<NotificationsIcon />
            </div>
        )
    }


    const renderLoginContainer = () => {
        return (
            <div className="auth-container">

                <div>
                    {fetchAttachedPlayer ? <CircularProgress size={12} /> :
                        !attachedUserId?.playerInfo?.name ?
                            <div>
                                {Number(attachedUserId.status) === 0 ?
                                    <>
                                        Неизвестный игрок <b onClick={() => setShowAtachPlayerPlayerModal(true)}>(Привязать)</b>
                                    </> :
                                    Number(attachedUserId.status) === 2 ?
                                        <div style={{ fontStyle: "italic" }}>
                                            Запрос отклонён <b onClick={() => setShowAtachPlayerPlayerModal(true)}>(Поробовать снова)</b>
                                        </div> :
                                        <div style={{ fontStyle: "italic" }}>
                                            Запрос отправлен
                                        </div>
                                }

                            </div> :
                            <>
                                <Link to="/profile">
                                    #{attachedUserId?.playerInfo?.place}{` `}
                                    {attachedUserId?.playerInfo?.name}{` `}
                                    {attachedUserId?.playerInfo?.surname} {` `}
                                    (r{attachedUserId?.playerInfo?.rating} )
                                </Link>
                            </>


                    }
                </div>

                <div className="profile-info">
                    <div><Link to="/profile-ads">Мои объявления ({attachedUserId?.totalAds}) </Link></div>
                    {oAuthLogin?.email && <>
                        <div>|</div>
                        <div><Link to="/profile">Моя анкета <ModeEditIcon className="edit-profile" /></Link></div>
                    </>}
                </div>

                <div>
                    {IS_ADMIN && renderAdminNotif()}
                    {attachedUserId?.email}
                    <div className="g-exit"
                        // onClick={() => onOAuth()}
                        onClick={() => setShowExitModal(true)}
                    ><ExitToAppIcon /></div>
                </div>


            </div >
        )
    }

    return (
        <div
            className={classNames({
                'header': true,
                'fixed-header': scrollPosition > 0
                //`${cName}`: true
            })}

        >
            {renderAttachPlayerModal()}
            {renderNotifModal()}
            {renderExitModal()}
            {renderApproveModal()}
            <div className="container">
                <div className="row my-header">
                    <div  className={classNames({
                            'col-md-12': true,
                          
                            //`${cName}`: true
                        })}
                    >
                        <div className="logo">

                            <Link to="/"><img src={topcup} alt="top-cup-logo" /></Link>
                            <Link to="/"><span className="site-text-color logo-span">Top</span> Cup rating</Link>
                        </div>

                        <div
                            className={classNames({
                                'g-auth': true,
                                'g-auth-no-entered': !(oAuthLogin && oAuthLogin?.email),
                                'mobile-g-auth': true
                                //`${cName}`: true
                            })}
                        >
                            <div className="g-auth-main-container">

                                {oAuthLogin && oAuthLogin?.email ? renderLoginContainer() :
                                    <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            const credentials = jwtDecode(credentialResponse.credential);
                                            CookieHelper("set", "oauth", JSON.stringify(credentials));
                                            window.location.reload();
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="menu site-bg-color">
                <div className="container new-header">
                    <div className="responsive-menu" onClick={() => setIsShowMobileMenu(!isShowMobileMenu)}>Меню</div>
                    <ul
                        className={classNames({
                            'responsive-menu-active': true,
                            'show-mobile-menu': isShowMobileMenu
                            //`${cName}`: true
                        })}
                    >

                        <li className={` ${checkForSelectedMenu("/", true) && "current-page"}`}><Link to="/">Главная</Link></li>
                        <li className={` ${checkForSelectedMenu("/ads") && "current-page"}`}><Link to="/ads">Объявления</Link> </li>
                        <li className={` ${checkForSelectedMenu("/check-delta") && "current-page"}`}><Link to="/check-delta">Проверить дельту</Link> </li>
                        <li className={` ${checkForSelectedMenu("/events") && "current-page"}`}><Link to="/events">События</Link></li>
                        <li className={` ${checkForSelectedMenu("/players") && "current-page"}`}><Link to="/players">Все игроки</Link></li>

                        {IS_ADMIN && <li className={` ${checkForSelectedMenu("/add-result") && "current-page"}`}><Link to="/add-result">Добавление результата</Link></li>}
                        {/* <li >

                            {!tcaToken ?
                                <Link to="#" onClick={() => setShowModal(true)} >Авторизация</Link> :
                                <>
                                    <Link to="#"  >
                                        <div className="exit-label" onClick={() => setShowExitModal(true)}>(выйти)</div></Link>

                                </>
                            }
                        </li> */}
                        {/* <li className="g-auth">
                            <div className="g-auth-main-container">

                                {oAuthLogin && oAuthLogin?.email ? renderLoginContainer() :
                                    <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            const credentials = jwtDecode(credentialResponse.credential);
                                            CookieHelper("set", "oauth", JSON.stringify(credentials));
                                            window.location.reload();
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                }
                            </div>

                        </li> */}
                    </ul>

                    <div
                        className={classNames({
                            'g-auth': true,
                            'g-auth-no-entered': !(oAuthLogin && oAuthLogin?.email)
                            //`${cName}`: true
                        })}
                    >
                        <div className="g-auth-main-container">

                            {oAuthLogin && oAuthLogin?.email ? renderLoginContainer() :
                                <GoogleLogin
                                    onSuccess={credentialResponse => {
                                        const credentials = jwtDecode(credentialResponse.credential);
                                        CookieHelper("set", "oauth", JSON.stringify(credentials));
                                        window.location.reload();
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header