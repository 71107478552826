import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Header from "../../components/header";
import Footer from "../../components/footer";
import classNames from "classnames"

import "./styles.scss"


function AppMainLayout({
    children
}) {

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (prop) => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div onScroll={handleScroll} className="main-container">
            {/* <div className="common-bg-container" /> */}
            <Header {...{ scrollPosition }}/>
            <div
                className={classNames({
                    'container': true,
                    'app-main-layout': true,
                    'page-scrolled': scrollPosition > 0
                })}
            >
                {children}
            </div>
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
    compose(
        connect(mapStateToProps, {}),
    )(AppMainLayout)
);