import React from "react";
import AppMainLayout from "../../../layouts/app-main-layout"

import "./styles.scss"

function PrivacyPolicy() {
    return (
        <AppMainLayout>
            <div className="row">
                <div className="col-md-12">
                Privacy policy
                </div>
            </div>
        </AppMainLayout>
    );
}


export default PrivacyPolicy