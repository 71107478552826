import {
    LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    SET_ATTACHED_PLAYER,
} from "../actions/Actions";

const initialState = {
    isFetching: false,
    attachedPlayerInfo: {},
    loginData: {
        login: ""
    },
};

export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return Object.assign({}, {
                ...state,
                isFetching: true,
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, {
                ...state,
                isFetching: false,
                loginData: action.payload,
            });
        case LOGIN_FAIL:
            return Object.assign({}, {
                ...state,
                isFetching: false,
                loginData: {},
            });
        case SET_ATTACHED_PLAYER:
            return Object.assign({}, {
                ...state,
                attachedPlayerInfo: {
                    email: action.payload.email,
                    status: action.payload.status,
                    totalAds: action.payload.totalAds,
                    playerInfo: {
                        ...action.payload.playerInfo[0]
                    }
                },
            });

        default:
            return state;
    }
}
